import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const Contact = ({ location, data }) => {
  const contact = data.contentfulCompany;
  return (
    <Layout location={location}>
      <Helmet title="Yhteystiedot - Hakio Consulting Oy" />

      <div className="absolute flex items-center justify-center h-full w-full">
        <div className="rounded-md shadow-md bg-gray-50 py-4 px-4 sm:py-16 sm:px-16 lg:col-span-2 lg:px-24 lg:py-24 w-full sm:w-auto">
          <div className="max-w-lg mx-auto sm:w-96">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Ota yhteyttä
            </h2>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Yritys</dt>
                <dd>
                  <p>{contact.title}</p>
                  <p>{contact.vatId}</p>
                </dd>
              </div>
              <div>
                <dt className="sr-only">Osoite</dt>
                <dd>
                  <p>{contact.street}</p>
                  <p>
                    {contact.zip} {contact.city}
                  </p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Yhteyshenkilö</dt>
                <dd>
                  <p className="font-medium">{contact.name}</p>
                </dd>
              </div>
              <div className="mt-4">
                <dt className="sr-only">Puhelinnumero</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <span className="ml-3">{contact.telephone}</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="ml-3">{contact.email}</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query ContactQuery {
    contentfulCompany {
      title
      name
      email
      website
      telephone
      street
      zip
      city
      vatId
    }
  }
`;
